import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import ContactForm from "../components/contactForm";
import DropdownQuestion from "../components/dropdown-question";
import Faq from "../components/faq";
import Hero from "../components/hero";
import Layout from "../components/layout";
import { checkPropertiesForNull } from "../utils/object";
import { SafeHtmlParser } from "../components/safe-html-parser";
import { BgImage } from "gbimage-bridge";
import uuid4 from "uuid4";
import LazyLoad from "react-lazyload";

const OneToOnePage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "back-care-one-to-one" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            altText
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
        backCarePageFieldGroups {
          backCareAboutSection {
            content
            heading
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            subHeading
          }
          backCareCoachingSection {
            heading
            shortDescription
            content {
              heading
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          backCareContactSection {
            buttonLabel
            formHeading
          }
          backCareFaqSection {
            heading
            faq {
              ... on WpFaq {
                id
                faqFields {
                  answer
                  page
                  question
                }
              }
            }
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          backCareFullWithTextSection {
            content
            heading
          }
          backCareIntroSection {
            cta {
              target
              title
              url
            }
            description
            heading
            subHeading
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          backCareJourneySection {
            heading
            cta {
              target
              title
              url
            }
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          backCareMassageSection {
            heading
            description
            imageGallery {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          backCarePricingSection {
            description
            heading
            cta {
              target
              title
              url
            }
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          backCareStorySection {
            description
            heading
            video {
              altText
              mediaItemUrl
            }
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          backCareTextWithBackgroundImageSection {
            content
            heading
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          backCareTwoColumnLeftImageSection {
            content
            heading
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          backCareTwoColumnSection {
            content
            heading
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            coachingElements {
              description
              heading
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, backCarePageFieldGroups },
  } = data;

  const siteUrl = data.site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const {
    backCareAboutSection,
    backCareCoachingSection,
    backCareContactSection,
    backCareFaqSection,
    backCareFullWithTextSection,
    backCareIntroSection,
    backCareJourneySection,
    backCareMassageSection,
    backCarePricingSection,
    backCareStorySection,
    backCareTextWithBackgroundImageSection,
    backCareTwoColumnLeftImageSection,
    backCareTwoColumnSection,
  } = backCarePageFieldGroups;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Back Care One-To-One",
        item: {
          url: `${siteUrl}/back-care-one-to-one`,
          id: `${siteUrl}/back-care-one-to-one`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/back-care-one-to-one`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          {backCareIntroSection &&
            !checkPropertiesForNull(backCareIntroSection, ["heading"]) && (
              <Hero
                title={
                  <>
                    {backCareIntroSection?.heading}
                    <br className="" />{" "}
                    <span className="text-secondary">
                      {backCareIntroSection?.subHeading}
                    </span>
                  </>
                }
                description={
                  <SafeHtmlParser
                    htmlContent={backCareIntroSection?.description}
                  />
                }
                img={
                  backCareIntroSection.image?.localFile.childImageSharp
                    .gatsbyImageData
                }
                imgAlt={backCareIntroSection.image?.altText}
                ctaButton={backCareIntroSection?.cta}
              />
            )}
          {backCareTextWithBackgroundImageSection &&
            !checkPropertiesForNull(backCareTextWithBackgroundImageSection, [
              "heading",
            ]) && (
              <section style={{ overflow: "hidden" }}>
                <BgImage
                  className="pb-5 pb-lg-9 position-relative bg-secondary pt-5 pt-lg-10 bg-image"
                  image={
                    backCareTextWithBackgroundImageSection?.backgroundImage
                      ?.localFile.childImageSharp.gatsbyImageData
                  }
                  alt={
                    backCareTextWithBackgroundImageSection?.backgroundImage
                      ?.altText
                  }
                  style={{
                    backgroundSize: "70%",
                  }}
                >
                  <Container
                    style={{ zIndex: 2 }}
                    className="position-relative pt-lg-4"
                  >
                    <Row className="">
                      <Col lg={8} className="">
                        <h2 className="pb-4 text-white display-5">
                          {backCareTextWithBackgroundImageSection?.heading}
                        </h2>
                        <div className="text-white">
                          <SafeHtmlParser
                            htmlContent={
                              backCareTextWithBackgroundImageSection?.content
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </BgImage>
              </section>
            )}

          {backCareCoachingSection &&
            !checkPropertiesForNull(backCareCoachingSection, ["heading"]) && (
              <section className="py-5 py-lg-7">
                <Container>
                  <Row className="g-5 justify-content-center">
                    <Col lg={8}>
                      <h2 className="  fs-2">
                        {backCareCoachingSection?.heading}
                      </h2>
                      {backCareCoachingSection?.shortDescription && (
                        <p>{backCareCoachingSection?.shortDescription}</p>
                        
                      )}

                      {backCareCoachingSection.content &&
                        backCareCoachingSection.content.length > 0 && (
                          <Row className="mt-lg-4">
                            {backCareCoachingSection.content.map((item) => (
                              <Col lg={6} key={uuid4()}>
                                <div className="d-flex align-items-start w-100 pb-3 ">
                                  <GatsbyImage
                                    style={{
                                      width: "20px",
                                      marginTop: "0.2rem",
                                    }}
                                    className="me-3 "
                                    image={
                                      item.image.localFile.childImageSharp
                                        .gatsbyImageData
                                    }
                                    alt={item.image?.altText}
                                  />
                                  <p
                                    className="d-inline-block mb-0"
                                    style={{ flex: 1 }}
                                  >
                                    {item.heading}
                                  </p>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        )}
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          {backCareStorySection &&
            !checkPropertiesForNull(backCareStorySection, ["heading"]) && (
              <section className="position-relative">
                <div
                  style={{ overflow: "hidden" }}
                  className="h-lg-60 h-md-70 h-80 w-100 bg-primary start-0 bottom-0 position-absolute"
                >
                  <div
                    style={{ top: "-4rem", left: "20%" }}
                    className="position-absolute d-none d-xl-block text-start w-100"
                  >
                    <GatsbyImage
                      className="me-3 w-60"
                      image={
                        backCareStorySection.backgroundImage.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={backCareStorySection.backgroundImage?.altText}
                    />
                  </div>
                  <div
                    style={{ top: "-2rem" }}
                    className="position-absolute start-0 text-end d-none d-md-block d-xl-none h-100 w-100"
                  >
                    <GatsbyImage
                      className="me-3 w-100"
                      image={
                        backCareStorySection.backgroundImage.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={backCareStorySection.backgroundImage?.altText}
                    />
                  </div>
                  <div
                    style={{ top: "2rem" }}
                    className="position-absolute start-0 d-md-none text-end d-xl-none h-100 w-100"
                  >
                    <GatsbyImage
                      className="me-3 w-100"
                      style={{ width: "180%" }}
                      image={
                        backCareStorySection.backgroundImage.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={backCareStorySection.backgroundImage?.altText}
                    />
                  </div>
                </div>
                <Container style={{ zIndex: 3 }} className="position-relative">
                  <Row>
                    <Col className="text-center">
                      {" "}
                      <LazyLoad height={700} offset={100}>
                        <div className="pb-5 ">
                          <ReactPlayer
                            controls
                            className="w-100 h-auto"
                            url={backCareStorySection?.video?.mediaItemUrl}
                          />
                        </div>
                      </LazyLoad>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="text-center" lg={8}>
                      <h2 className="pb-4 text-white display-5">
                        {backCareStorySection?.heading}
                      </h2>
                      {backCareStorySection.description && (
                        <div className="text-white pb-5">
                          <SafeHtmlParser
                            htmlContent={backCareStorySection.description}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          <section className="py-5 pt-lg-5 pb-lg-7">
            <Container>
              {backCareFullWithTextSection &&
                !checkPropertiesForNull(backCareFullWithTextSection, [
                  "heading",
                ]) && (
                  <Row>
                    <Col lg={10} xl={8}>
                      <h2 className=" pb-4  fs-2">
                        {backCareFullWithTextSection?.heading}
                      </h2>
                      {backCareFullWithTextSection?.content && (
                        <SafeHtmlParser
                          htmlContent={backCareFullWithTextSection?.content}
                        />
                      )}
                    </Col>
                  </Row>
                )}
              {backCareTwoColumnSection &&
                !checkPropertiesForNull(backCareTwoColumnSection, [
                  "heading",
                ]) && (
                  <Row className="g-5 g-lg-7 justify-content-between align-items-center">
                    <Col lg={{ span: 5, order: "last" }}>
                      <div style={{ borderRadius: "25px", overflow: "hidden" }}>
                        <GatsbyImage
                          className="w-100"
                          image={
                            backCareTwoColumnSection.image?.localFile.childImageSharp.gatsbyImageData
                          }
                          alt={backCareTwoColumnSection.image?.altText}
                        />
                      </div>
                    </Col>
                    <Col lg={7}>
                      <h2 className=" fs-2">
                        {backCareTwoColumnSection?.heading}
                      </h2>
                      {backCareTwoColumnSection?.content && (
                        <SafeHtmlParser
                          htmlContent={backCareTwoColumnSection?.content}
                        />
                      )}

                      {backCareTwoColumnSection.coachingElements &&
                        backCareTwoColumnSection.coachingElements.length >
                          0 && (
                          <>
                            {backCareTwoColumnSection.coachingElements.map(
                              (item) => (
                                <DropdownQuestion
                                  key={uuid4()}
                                  question={item.heading}
                                  answer={item.description}
                                />
                              )
                            )}
                          </>
                        )}
                    </Col>
                  </Row>
                )}
            </Container>
          </section>

          {backCarePricingSection &&
            !checkPropertiesForNull(backCarePricingSection, ["heading"]) && (
              <BgImage
                className="py-5 pt-lg-5 pt-xl-5 position-relative bg-secondary bg-right-image"
                image={
                  backCarePricingSection?.backgroundImage?.localFile
                    .childImageSharp.gatsbyImageData
                }
                alt={backCarePricingSection?.backgroundImage?.altText}
                style={{
                  backgroundPosition: "left center",
                }}
              >
                <Container
                  style={{ zIndex: 2 }}
                  className="position-relative text-center "
                >
                  <Row className="justify-content-center">
                    <Col className="text-center">
                      <h2 className="pb-4 text-white display-5">
                        {backCarePricingSection?.heading}
                      </h2>
                    </Col>
                  </Row>
                  <Row className="align-items-center justify-content-center g-3 g-xl-7">
                    <Col>
                      <div
                        style={{
                          background: "rgba(0, 0, 0, 0.32)",
                          borderRadius: "6px",
                        }}
                        className="w-100 h-100 text-white py-3 px-4 "
                      >
                        <div className="pb-0 mb-0 fs-5">
                          <SafeHtmlParser
                            htmlContent={backCarePricingSection?.description}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {backCarePricingSection.cta &&
                    backCarePricingSection.cta.url && (
                      <Row>
                        <Col>
                          <Button
                            as={Link}
                            to={backCarePricingSection.cta.url}
                            variant="primary"
                            className="w-100 w-md-auto py-3 mt-4 text-white px-5"
                            target={
                              backCarePricingSection.cta.target ?? "_self"
                            }
                          >
                            {backCarePricingSection.cta.title}
                          </Button>
                        </Col>
                      </Row>
                    )}
                </Container>
              </BgImage>
            )}
          {backCareMassageSection &&
            !checkPropertiesForNull(backCareMassageSection, ["heading"]) && (
              <section>
                <Container className=" py-5">
                  {backCareMassageSection.imageGallery &&
                    backCareMassageSection.imageGallery.length > 0 && (
                      <Row className="g-3 ">
                        {backCareMassageSection.imageGallery.map((item) => (
                          <Col lg={4} key={uuid4()}>
                            <GatsbyImage
                              className="w-100"
                              image={
                                item?.localFile.childImageSharp.gatsbyImageData
                              }
                              alt={item?.altText}
                            />{" "}
                          </Col>
                        ))}
                      </Row>
                    )}

                  <Row>
                    <Col lg={10} xl={8}>
                      <h2 className=" fs-2 mt-4">
                        {backCareMassageSection?.heading}
                      </h2>
                      {backCareMassageSection?.description && (
                        <SafeHtmlParser
                          htmlContent={backCareMassageSection?.description}
                        />
                      )}
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          {backCareTwoColumnLeftImageSection &&
            !checkPropertiesForNull(backCareTwoColumnLeftImageSection, [
              "heading",
            ]) && (
              <section className="py-5 py-lg-7 bg-secondary">
                <Container>
                  <Row className="align-items-center g-5 g-lg-7">
                    <Col lg={6}>
                      <GatsbyImage
                        className="w-100"
                        image={
                          backCareTwoColumnLeftImageSection.image?.localFile.childImageSharp.gatsbyImageData
                        }
                        alt={backCareTwoColumnLeftImageSection.image?.altText}
                      />{" "}
                    </Col>
                    <Col lg={6}>
                      <h2 className=" fs-2  mb-4 text-white">
                        {backCareTwoColumnLeftImageSection?.heading}
                      </h2>
                      {backCareTwoColumnLeftImageSection.content && (
                        <SafeHtmlParser
                          htmlContent={
                            backCareTwoColumnLeftImageSection?.content
                          }
                        />
                      )}
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          <section
            style={{
              background:
                "linear-gradient(200deg, rgba(245, 249, 252, 0.00) 3.13%, #F5F9FC 51.56%, rgba(245, 249, 252, 0.00) 100%), linear-gradient(180deg, #F5F9FC 0%, #F5F9FC 0.01%, rgba(250, 252, 254, 0.00) 31.77%, rgba(250, 252, 254, 0.00) 65.63%, #F5F9FC 100%), radial-gradient(116.72% 75.58% at 101.01% 32.67%, rgba(255, 147, 140, 0.41) 2.08%, rgba(245, 249, 252, 0.41) 100%), radial-gradient(126.79% 78.09% at -2.67% 63.84%, rgba(116, 194, 218, 0.42) 2.08%, rgba(245, 249, 252, 0.42) 100%)",
            }}
            id="form"
            className="py-5 py-lg-7"
          >
            <Container>
              <Row className="justify-content-center">
                <Col lg={8} xl={7}>
                  <h2 className="display-5 mb-4 text-center">
                    {backCareContactSection.formHeading ??
                      "Book a discovery call"}
                  </h2>
                  <ContactForm btnText={backCareContactSection?.buttonLabel} />
                </Col>
              </Row>
            </Container>
          </section>
          {backCareAboutSection &&
            !checkPropertiesForNull(backCareAboutSection, ["heading"]) && (
              <section className="pb-5 pt-md-5 py-xl-7">
                <Container>
                  <Row className="g-5 g-lg-6 align-items-center">
                    <Col md={4}>
                      <GatsbyImage
                        className="w-100"
                        image={
                          backCareAboutSection.image?.localFile.childImageSharp
                            .gatsbyImageData
                        }
                        alt={backCareAboutSection.image?.altText}
                      />
                    </Col>
                    <Col md={8}>
                      <h2 className=" fs-2 mt-4  ">
                        {backCareAboutSection?.heading}
                      </h2>
                      <h3 className="text-secondary fs-4">
                        {backCareAboutSection?.subHeading}
                      </h3>
                      <SafeHtmlParser
                        htmlContent={backCareAboutSection?.content}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}

          <BgImage
            className="py-5 pt-lg-7 pt-xl-9 position-relative bg-secondary bg-right-image"
            image={
              backCareFaqSection?.backgroundImage?.localFile.childImageSharp
                .gatsbyImageData
            }
            alt={backCareFaqSection?.backgroundImage?.altText}
            style={{
              backgroundPosition: "left center",
            }}
          >
            <Container
              style={{ zIndex: 2 }}
              className="position-relative text-center "
            >
              <Faq
                heading={backCareFaqSection?.heading}
                faqs={backCareFaqSection?.faq}
              />
            </Container>
          </BgImage>

          {backCareJourneySection &&
            !checkPropertiesForNull(backCareJourneySection, ["heading"]) && (
              <BgImage
                className="position-relative py-5 py-lg-7 py-xl-10"
                image={
                  backCareJourneySection?.backgroundImage?.localFile
                    .childImageSharp.gatsbyImageData
                }
                alt={backCareJourneySection?.backgroundImage?.altText}
              >
                <section style={{ overflow: "hidden" }}>
                  <Container
                    style={{ zIndex: 3 }}
                    className="mt-xl-10 position-relative"
                  >
                    <Row>
                      <Col className="text-center">
                        <h2 className="pb-5 display-3 d-none d-lg-block">
                          <SafeHtmlParser
                            htmlContent={backCareJourneySection?.heading}
                          />
                        </h2>
                        <h2 className="pb-5  display-5 d-lg-none">
                          <SafeHtmlParser
                            htmlContent={backCareJourneySection?.heading}
                          />
                        </h2>
                        {backCareJourneySection.cta &&
                          backCareJourneySection.cta?.url && (
                            <Button
                              as={Link}
                              to={backCareJourneySection.cta?.url}
                              variant="primary"
                              className="w-100 w-md-auto py-3  text-white px-5"
                              target={
                                backCareJourneySection.cta?.target ?? "_self"
                              }
                            >
                              {backCareJourneySection.cta?.title}
                            </Button>
                          )}
                      </Col>
                    </Row>
                  </Container>
                </section>
              </BgImage>
            )}
        </Layout>
      </div>
    </>
  );
};

export default OneToOnePage;
